import React, { useState, useEffect, useDebugValue } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/business.css"
import "../styles/faq.css"

import VehicleImage from "../images/register-vehicle.png"
import RegisterVehicle from "../images/register-vehicle.svg"
import AssignPartnerToDriver from "../images/muvit-partner-assign-screen.png"
import Asset16 from "../images/Asset16.png"
import Asset17 from "../images/Asset17.png"
import Asset18 from "../images/Asset18.png"

import faq_data from "../data/faq.json"

import PlayStoreImage from "../images/app-store-icons/dl_playstore@2x.png"
import AppStoreImage from "../images/app-store-icons/dl_appstore@2x.png"

const openVehicleRegistration = e => {
  let target = e.target
  let partner_intro = document.querySelector(".partner-intro")
  window.scrollTo(0, -window.scrollY)
  partner_intro.classList.toggle("partner-form-active")
  document.body.classList.toggle("disable-scroll")
}

const closeRegistrationForm = e => {
  let target = e.target
  let partner_intro = document.querySelector(".partner-intro")
  if (!partner_intro.classList.contains("partner-form-active")) return

  if (target.classList.contains("partner-signup-form")) {
    partner_intro.classList.toggle("partner-form-active")
    document.body.classList.toggle("disable-scroll")
  }
}

const BusinessPage = () => {
  const partnerFaq = faq_data
    .filter(o => o.type === "partner")
    .map((o, i) => {
      o.isChecked = true
      o.id = i + 1
      return o
    })
  const [faqs, updateFaqs] = useState(partnerFaq)

  const toggleCheck = dataObj => event => {
    let elem = event.target
    let modified = [...faqs]
    let index
    modified.forEach((o, i) => {
      if (o.id == dataObj.id) index = i
    })
    modified[index] = {
      ...dataObj,
      isChecked: !dataObj.isChecked,
    }
    updateFaqs(modified)
  }
  useEffect(() => {})

  return (
    <Layout>
      <SEO title="Business" />
      <section className="section-intro partner-intro">
        <div className="section--content">
          <div className="home--left--section">
            <h1 className="home--banner-text">
              Join the league. <br />
              Earn money the smart <br />
              and hassle free way
            </h1>
            <h2 className="home--banner-text-sub">Partner with muvit.</h2>
            <h4 className="home--banner-text-small">
              Turn KM into money and get a bigger pocket
            </h4>
          </div>
          <div className="home--right--section">
            <div
              className="partner-signup-form"
              onClick={closeRegistrationForm}
            >
              <div className="partner-signup-content">
                <h3 className="partner-signup-header">Attach Vehicle Now</h3>
                <div className="form--control">
                  <input type="text" placeholder="Full Name" />
                </div>
                <div className="form--control">
                  <input type="text" placeholder="Email Address" />
                </div>
                <div className="form--control-group">
                  <div className="form--control">
                    <select name="">
                      <option value="">City</option>
                    </select>
                  </div>
                  <div className="form--control">
                    <select name="">
                      <option value="">Vehicle Type</option>
                    </select>
                  </div>
                </div>
                <div className="form--control">
                  <input type="text" placeholder="Phone Number" />
                </div>
                <button
                  className="cta cta-partner-registration"
                  onClick={e =>
                    (window.location.href =
                      "https://play.google.com/store/apps/details?id=com.hts.muvitpartner")
                  }
                >
                  Register
                </button>
              </div>
            </div>
          </div>
          <button
            className="cta cta--get--quote bg--green cta--register-vehicle"
            onClick={e =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.hts.muvitpartner")
            }
          >
            Register Vehicle
          </button>
          <div className="register-partner-vehicle">
            <img src={RegisterVehicle} />
          </div>
          <div className="get-partner-app">
            <h2 className="header-line-1">Start earning immediately.</h2>
            <h3 className="header-line-2">
              Register your vehicle and download the mobile app to continue.
            </h3>
            <div className="download-app-section">
              <div className="download-app-section-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.hts.muvitpartner"
                  className="app-store-icon"
                  data-app-store="google-play"
                >
                  <img src={PlayStoreImage} />
                </a>
              </div>
              <div className="download-app-section-content">
                <a
                  href="#"
                  className="app-store-icon"
                  data-app-store="play-store"
                >
                  <img src={AppStoreImage} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="partner-app-board">
        <div className="section--content flex-section">
          <div className="partner-feature-image">
            <img src={VehicleImage} />
            <div className="partner-assign-driver-steps">
              <h2>Assign drivers to fulfill orders.</h2>
              <p>
                Accept a request and assign a driver to fulfil request. Monitor
                driver activities from your mobile app
              </p>
            </div>
          </div>
          <div className="app--item--image">
            <img src={AssignPartnerToDriver} />
          </div>
        </div>
      </section>
      <section className="partners-benefits mt-50">
        <h2 className="section--content--header center">Partners Benefits</h2>
        <div className="section--content">
          <ul className="partners-benefit-list">
            <li>
              <div className="benefit-item-img">
                <img src={Asset17} />
              </div>
              <h2>Weekly/Daily Payouts</h2>
              <p>Cash out when you desire. its your money.</p>
            </li>
            <li>
              <div className="benefit-item-img">
                <img src={Asset18} />
              </div>
              <h2>Better Earning Percentage</h2>
              <p>Our commissions are excellent. We are sure of our word.</p>
            </li>
            <li>
              <div className="benefit-item-img">
                <img src={Asset16} />
              </div>
              <h2>Get Regular Trips</h2>
              <p>Earn like a pro with our large customer base</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="faq-section faq-section-content">
        <h2 className="section--content--header center">
          Frequently asked questions
        </h2>
        <div className="section--content">
          <div className="faq-container">
            <ul>
              {faqs.map(o => (
                <li key={o.id}>
                  <input
                    type="checkbox"
                    onChange={toggleCheck(o)}
                    checked={o.isChecked}
                  />
                  <i></i>
                  <h2>{o.question}</h2>
                  <p dangerouslySetInnerHTML={{ __html: o.answer }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BusinessPage
