const faq_data = [
  {
    question: "Not sure which area your starting point or destination is in?",
    answer:
      "If you don’t see your exact locations we recommend you to find your location on the Map",
    type: "customer",
  },
  {
    question:
      "I urgently need a vehicle, but I can’t find a driver! What do I do?",
    answer:
      "Drivers may not always be available during peak hours. We recommend that you try again.",
    type: "customer",
  },
  {
    question: "Why were the actual prices a bit different from the quote?",
    answer:
      "In distant locations, the actual price may differ from the quote. We recommend you to clarify the situation with your driver and figure out the actual price of the trip beforehand, to avoid any misunderstandings. You may also call Muvit for inquiries.",
    type: "customer",
  },
  {
    question: "Are toll fees included in the app’s quoted price?",
    answer:
      "No, the app’s quoted price for your order does not include toll fees. ",
    type: "customer",
  },
  {
    question:
      "Why doesn’t the license plate number of the vehicle match the one displayed in the app’s information?",
    answer:
      "To maintain our strict quality control, Muvit does not allow our drivers to shift their orders to another person (ie. a driver not registered with Muvit). If the license plate number of the actual vehicle does not match with the one displayed on the app, please call Muvit immediately to report the case.",
    type: "customer",
  },
  {
    question:
      "My driver is late, or has not appeared at all, without any proper notice. What do I do?",
    answer:
      "Please call/Cancel and report this to Muvit. We will assign new vehicles for you.",
    type: "customer",
  },
  {
    question:
      "What do I do if I either want to change the starting point or destination of my order, cancel the order, or change the vehicle choice after the order has been confirmed?",
    answer:
      "If you’d like to cancel or edit the order, please contact your driver directly. Our drivers will be happy to accommodate the changes.",
    type: "customer",
  },
  {
    question: "I’m not sure if my goods exceed the weight or dimension limits?",
    answer:
      "Muvit provides different types of vehicles for carrying goods of various weights and dimensions. A regular vehicle can carry up to 907.2kg; If you’re not sure which vehicle to choose, please refer to the Category Description. Alternatively, you may contact your driver directly for inquiries.",
    type: "customer",
  },
  {
    question:
      "I’ve confirmed my order and then received the driver’s information. What should I do now?",
    answer:
      "When you see the driver’s information, it means that the driver has already received your order. You may now call or wait for the driver’s call to confirm the exact pickup point and destination.",
    type: "customer",
  },
  {
    question: "What if there’s no vehicle type that’s suitable for my order?",
    answer:
      "If you would like a 16 ton/15,000kg truck or other vehicle types, please call Muvit and we will arrange everything accordingly",
    type: "customer",
  },
  {
    question: "What are Muvit operating hours?",
    answer:
      "Our app works 24/7. Just order on our app anytime for our delivery and moving services. If you’d like to order through our hotline or like to call us for any inquiries, our office hours are from Monday to Sunday morning from 7 a.m. to 12 a.m. midnight.",
    type: "customer",
  },
  {
    question: "How long is the transaction record stored for?",
    answer:
      "Unless you uninstall the Muvit app, the transaction record will always remain on your phone.",
    type: "customer",
  },
  {
    question: "How can I place an order?",
    answer:
      "A partner is required to register an account in Muvit app. We do not provide service hotline for order placement.",
    type: "partner",
  },
  {
    question: "Are there any size or weight limits on the packages?",
    answer:
      "Yes. The weight limits are: 5kg, 10kg, 15kg, 20kg; the size limits are: 20x20x10cm，30x30x30cm，40x40x40cm，50x50x50cm，60x60x60cm",
    type: "partner",
  },
  {
    question: "How does Muvit charge me for each order?",
    answer:
      "The price of each delivery depends on package size, weight, distance and delivery time (45 minutes within the same locality and same-day delivery).",
    type: "partner",
  },
  {
    question: "Does delivery partner provide wrapping materials?",
    answer: "No, partners should ensure that the package is packed properly.",
    type: "partner",
  },
  {
    question: "Any restricted area?",
    answer: "Yes, delivery services are not available in some remote areas.",
    type: "partner",
  },
  {
    question: "When should I place the order? Can I choose delivery time?",
    answer:
      "We provide 45 minutes and same-day delivery service and you can choose the time of delivery.",
    type: "partner",
  },
  {
    question: "How can I confirm the identity of a delivery partner?",
    answer:
      "You can ask the Muvit delivery partner to provide the order number and his/her contact number for confirmation.",
    type: "partner",
  },
  {
    question: "Can I cancel an order?",
    answer:
      "Yes, you are entitled to a refund of the delivery fee if the order is cancelled before the delivery partner picks up the package. <hr /> If you cancel an order after the delivery partner picks up the package, you are required to pay for the full delivery fee.",
    type: "partner",
  },
  {
    question: "How do I check the order status?",
    answer:
      "Please click “open Menu> Track Delivery > in the App to check delivery status.",
    type: "partner",
  },
  {
    question:
      "Can the Delivery Partner keep my package overnight for next day delivery?",
    answer:
      "No, we provide 45 minutes delivery and same-day delivery. All deliveries need to be completed within the same day. We allow partners to place an order one day in advance.",
    type: "partner",
  },
  {
    question: "Will Muvit refuse an order?",
    answer:
      "Muvit has the right to refuse or accept an order: <hr /> The customer’s request does not comply with our Terms and Conditions; <hr /> The customer fails to enter a valid address and other required fields that are recognisable by the Muvit system; <hr /> The package or item is not suitable for transport; <hr /> The package may violate the laws and regulations in Lagos; or is detrimental to public order, or injurious to public morals; <hr /> The package or item are of the following items: <hr /> Explosives or other hazardous materials or any similar packages that are likely to cause damage to other packages; <hr /> packages specifically determined by Muvit to be unacceptable, including but not limited to combustible, inflammable, poisonous and toxic materials, livestock, dogs, cats, little birds, guns, machetes and swords etc.; and <br/> Any items that are prohibited to be delivered under the laws and regulations of Lagos; and <hr /> Muvit reserves the right to make the final decision. <hr /> Any special arrangements under bad weather conditions (heavy rains, storms)? <hr /> When severe weather warning is issued, please refer to the latest information and arrangement from Muvit.",
    type: "partner",
  },
  {
    question: "What should I do when the delivery is delayed?",
    answer:
      "When the delivery partner picks up the order, you will monitor in real-time and track your package. You may contact the delivery partner from time to time to check the delivery status. If you fail to contact the delivery Partner, please contact Muvit for further arrangement.",
    type: "recipient",
  },
  {
    question: "What if the delivery partner delivers the wrong package?",
    answer: "You should contact Muvit and we will make further arrangement.",
    type: "recipient",
  },
  {
    question: "What if the package is damaged?",
    answer: "The recipient can contact Muvit for further arrangement.",
    type: "recipient",
  },
  {
    question:
      "What if I‘m not around when the delivery partner arrives? Can a third party accept the package on my behalf?",
    answer:
      "Yes, but the delivery partner should contact the person that placed the request to confirm that the third party may accept the package and sign on the App on your behalf. If you fail to contact the partner, please contact Muvit to follow up.",
    type: "recipient",
  },
  {
    question:
      "What if I ‘m not around when the delivery partner arrives? Can you arrange package to deliver to another address?",
    answer:
      "Yes, but the either the partner or the recipient will bear the extra delivery charge (i.e. the delivery charge from the original address to the new address).",
    type: "recipient",
  },
  {
    question:
      "What if I‘m not around when the delivery partner arrives? Can you arrange the package to be returned to the partner’s address?",
    answer:
      "Yes, but either the partner or the recipient shall bear the extra delivery charge (i.e. the same delivery charge as the original order).",
    type: "recipient",
  },
]

export default faq_data
